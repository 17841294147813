<template>
  <div>
    <b-row>
      <b-col cols="12" md="6">
        <h5>신한은행 환율</h5>
        <b-row class="mb-2">
          <b-col cols="6" xl="4">
          <small>신한은행 실시간 환율</small>
          <i class="fa fa-question-circle ml-1" v-b-tooltip="'현재의 신한 은행 전신환매입환율'"></i><br/>
          <b-form inline>
            <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="usdFxRate" type="text" readonly></b-form-input>
            KRW/USD
          </b-form>
        </b-col>
          <b-col cols="6" xl="4">
            <small>신한은행 유로대미 환산율</small>
          <i class="fa fa-question-circle ml-1" v-b-tooltip="'현재의 신한 은행 유로대미환산율'"></i><br/>
          <b-form inline>
            <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="usdEurRate" type="text" readonly></b-form-input>
            EUR/USD
          </b-form>
        </b-col>
        <b-col cols="6" xl="4">
            <small>신한은행 달러대미 환산율</small>
          <i class="fa fa-question-circle ml-1" v-b-tooltip="'현재의 신한 은행 유로대미환산율'"></i><br/>
          <b-form inline>
            <b-form-input class="w-70px text-center mr-1 px-1" type="text" readonly value="1"></b-form-input>
            USD/USD
          </b-form>
        </b-col>
      </b-row>
      <b-row>
        <b-col>부티크 상품 원본가 통화에 따라 EUR/USD 혹은 USD/USD 환율 자동 적용</b-col>
        </b-row>
      <h5 class="mt-4">병행수입 상품 <a href="https://docs.google.com/spreadsheets/d/18KAdZIFu37PoWoV_wB4odx1q_TlCWQCi4CIEywZZcu8/edit#gid=508922120" class="fs-16" target="_blank">(참고링크)</a></h5>
      <h5 class="mt-1">가격결정 요소 설정</h5>  
      <b-row class="mb-2">
          <b-col cols="6" xl="4">
            <small>정액 운영비</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'음수/양수 모두 가능'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="ready.opAmount" type="text"></b-form-input>
              원
            </b-form>
          </b-col>
          <b-col cols="6" xl="4">
            <small>정률 운영비 1</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'음수/양수 모두 가능'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="ready.opRate1" type="text"></b-form-input>
              %
            </b-form>
          </b-col>
          <b-col cols="6" xl="4">
            <small>정률 운영비 2</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'음수/양수 모두 가능'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="ready.opRate2" type="text"></b-form-input>
              %
            </b-form>
          </b-col>
          
        </b-row>
        <div class="mt-4">
          <b-btn variant="success" @click="save()" :disabled="busy.save">저장<b-spinner class="ml-1" small v-if="busy.save"></b-spinner></b-btn>
          <b-btn variant="outline-info" class="ml-1" @click="getPricing()" :disabled="busy.get">새로고침<b-spinner class="ml-1" small v-if="busy.get"></b-spinner></b-btn>
        </div>
        <h5 class="mt-3">시뮬레이션</h5>
        <small>KRW 가격</small>
        <b-form-input class="w-100px text-center mr-1 px-1" v-model.number="krwPrice" type="text"></b-form-input>
        <div>
          ({{ krwPrice }} + {{ krwPrice }} * {{ ready.opRate1 || 0 }} / 100 + {{ krwPrice }} * {{ ready.opRate2 || 0 }} / 100 + {{ ready.opAmount || 0 }}) / {{ usdFxRate }}
        </div>
        <b-row>
          <b-col>
            <small>계산된 KRW 가격</small>
            <b-form-input class="w-100px text-center mr-1 px-1" v-model.number="krwBeforePrice" type="text" readonly></b-form-input>
          </b-col>
          <b-col>
            <small>USD 가격</small>
            <b-form-input class="w-100px text-center mr-1 px-1" v-model.number="usdPrice" type="text" readonly></b-form-input>
          </b-col>
        </b-row>

        <h5 class="mt-5">부티크 상품</h5>
        <h5 class="mt-1">가격결정 요소 설정</h5>
        <b-row class="mb-2">
          <b-col cols="6" xl="4">
            <small>정액 운영비</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'음수/양수 모두 가능'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="ready.opBtqAmount" type="text"></b-form-input>
              원
            </b-form>
          </b-col>
          <b-col cols="6" xl="4">
            <small>정률 운영비 1</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'음수/양수 모두 가능'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="ready.opBtqRate1" type="text"></b-form-input>
              %
            </b-form>
          </b-col>
          <b-col cols="6" xl="4">
            <small>정률 운영비 2</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'음수/양수 모두 가능'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="ready.opBtqRate2" type="text"></b-form-input>
              %
            </b-form>
          </b-col>
          
        </b-row>
        <div class="mt-3">
          <b-btn variant="success" @click="save()" :disabled="busy.save">저장<b-spinner class="ml-1" small v-if="busy.save"></b-spinner></b-btn>
          <b-btn variant="outline-info" class="ml-1" @click="getPricing()" :disabled="busy.get">새로고침<b-spinner class="ml-1" small v-if="busy.get"></b-spinner></b-btn>
        </div>
        <h5 class="mt-3">시뮬레이션</h5>
        <small>EUR 가격</small>
        <b-form-input class="w-100px text-center mr-1 px-1" v-model.number="eurPrice" type="text"></b-form-input>
        <div>
          ({{ eurPrice }} + {{ eurPrice }} * {{ ready.opBtqRate1 || 0 }} / 100 + {{ eurPrice }} * {{ ready.opBtqRate2 || 0 }} / 100 + {{ ready.opBtqAmount || 0 }}) * {{ usdEurRate }}
        </div>
        <b-row>
          <b-col>
            <small>계산된 EUR 가격</small>
            <b-form-input class="w-100px text-center mr-1 px-1" v-model.number="eurBeforePrice" type="text" readonly></b-form-input>
          </b-col>
          <b-col>
            <small>USD 가격</small>
            <b-form-input class="w-100px text-center mr-1 px-1" v-model.number="btqUsdPrice" type="text" readonly></b-form-input>
          </b-col>
        </b-row>
      </b-col>
      
      <b-col cols="12" md="6" class="border-left">
        <h5>현재 병행수입 상품 운영 정책</h5>
        <b-row class="mb-2">
          <b-col cols="6" xl="4">
            <small>정액 운영비</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'음수/양수 모두 가능'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="now.opAmount" type="text" disabled></b-form-input>
              원
            </b-form>
          </b-col>
          <b-col cols="6" xl="4">
            <small>정률 운영비 1</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'음수/양수 모두 가능'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="now.opRate1" type="text" disabled></b-form-input>
              %
            </b-form>
          </b-col>
          <b-col cols="6" xl="4">
            <small>정률 운영비 2</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'음수/양수 모두 가능'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="now.opRate2" type="text" disabled></b-form-input>
              %
            </b-form>
          </b-col>
        </b-row>
          <h5 class="mt-4">현재 부티크 운영 정책</h5>
        <b-row class="mb-2">
          <b-col cols="6" xl="4">
            <small>정액 운영비</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'음수/양수 모두 가능'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="now.opBtqAmount" type="text" disabled></b-form-input>
              원
            </b-form>
          </b-col>
          <b-col cols="6" xl="4">
            <small>정률 운영비 1</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'음수/양수 모두 가능'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="now.opBtqRate1" type="text" disabled></b-form-input>
              %
            </b-form>
          </b-col>
          <b-col cols="6" xl="4">
            <small>정률 운영비 2</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'음수/양수 모두 가능'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="now.opBtqRate2" type="text" disabled></b-form-input>
              %
            </b-form>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col cols="6" xl="3">
            <small>현재 적용 환율</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'현재 적용된 달러 환율 정책'"></i><br/>
            <b-form inline>
              <b-form-input class="w-60px text-center mr-1 px-1" v-model.number="now.usdkrw" type="text" disabled></b-form-input>
              KRW/USD
            </b-form>
          </b-col>
          <b-col cols="6" xl="3">
            <small>현재 유로대미 환산율</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'현재 적용된 유로대미 환산율 정책'"></i><br/>
            <b-form inline>
              <b-form-input class="w-60px text-center mr-1 px-1" v-model.number="now.usdeur" type="text" disabled></b-form-input>
              EUR/USD
            </b-form>
            </b-col>
            <b-col cols="6" xl="3">
            <small>현재 달러대미 환산율</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'현재 적용된 달러대미 정책'"></i><br/>
            <b-form inline>
              <b-form-input class="w-60px text-center mr-1 px-1" type="text" disabled value="1"></b-form-input>
              USD/USD
            </b-form>
            </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="12" xl="12">변경 된 정책은 다음날 오전 9시 55분 이후 동기화 되는 상품부터 적용됩니다.</b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped>
</style>

<script>

export default {
  name: 'ShopifyPricing',
  title: 'Shopify 가격관리',
  data() {
    return {
      ready: {},
      now: {},
      usdFxRate: 1340,
      krwPrice: 1000000,
      krwBeforePrice: 0,
      usdPrice: 0,
      busy: {get: false, save: false}, 
      usdEurRate:1.0509,
      eurBeforePrice: 0,
      eurPrice: 100,
      btqUsdPrice: 115
    }
  },
  created() {
    this.getPricing();
    this.getFx();
  },
  watch: {
    ready: {
      deep: true,
      handler() {
        this.calcUsdPrice();
      },
    },
    krwPrice() {
      this.calcUsdPrice();
    },
    eurPrice() {
      this.calcUsdPrice();
    }
  },
  methods: {
    async getPricing() {
      this.busy.get = true;
      const j = await this.$api.getJson(`/goods/shopify/pricing`);
      this.busy.get = false;
      if (j) {
        this.ready = j.ready || {};
        this.now = j.now || {};
      }
    },
    getFx() {
      this.$api.getJson('/shop/shinhanFx').then(j => {
        if (!j.data.dataBody) { // 고시환율이 확정전 상태입니다. 고시환율 확정후 조회할 수 있습니다.
          setTimeout(this.getFx, 3000);
          return;
        }
        const usd = j.data.dataBody.R_RIBF3733_1.find(e => e['통화CODE'] === 'USD');
        const eur = j.data.dataBody.R_RIBF3733_1.find(e => e['통화CODE'] === 'EUR');
        this.usdFxRate = usd['전신환매입환율'];
        this.usdEurRate = eur['대미환산환율'];
      });
    },
    calcUsdPrice() {
      const r = this.ready;
      this.krwBeforePrice = Math.floor(this.krwPrice + this.krwPrice * ((r.opRate1 || 0) + (r.opRate2 || 0)) / 100 + (r.opAmount || 0));
      this.usdPrice = Math.floor(this.krwBeforePrice / this.usdFxRate * 100) / 100;
      this.eurBeforePrice = Math.floor(this.eurPrice + this.eurPrice * ((r.opBtqRate1 || 0) + (r.opBtqRate2 || 0)) / 100 + (r.opBtqAmount || 0));
      this.btqUsdPrice = Math.floor(this.eurBeforePrice * this.usdEurRate);
    },
    async save() {
      this.busy.save = true;
      const j = await this.$api.postJson(`/goods/shopify/setPricing`, this.ready);
      this.busy.save = false;

      if (j) {
        this.$alertTop('저장되었습니다. 다음 가격싱크 주기에 반영됩니다.');
      }
    },
  }
}
</script>
